import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.css';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Home from './pages/home/Home'
const LazyAbout = React.lazy(() => import('./pages/about/About'))
const LazyFashion = React.lazy(() => import('./pages/fashion/Fashion'))
const LazyCuisine = React.lazy(() => import('./pages/cuisine/Cuisine'))
const LazyYacht = React.lazy(() => import('./pages/yacht/Yacht'))
const LazyBeauty = React.lazy(() => import('./pages/beauty/Beauty'))


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Nav></Nav>
        <Routes>
          <Route path='*'/>
          <Route path='/' element={<Home/>} />
          <Route path='/med-fashion' element={
            <React.Suspense fallback='Loading...'>
              <LazyFashion />
            </React.Suspense>
          }/>
          <Route path='/med-cuisine' element={
            <React.Suspense fallback='Loading...'>
              <LazyCuisine />
            </React.Suspense>
          }/>
          <Route path='/med-yacht-design' element={
            <React.Suspense fallback='Loading...'>
              <LazyYacht />
            </React.Suspense>
          }/>
           <Route path='/med-beauty' element={
            <React.Suspense fallback='Loading...'>
              <LazyBeauty />
            </React.Suspense>
          }/>
          <Route path='/about' element={
            <React.Suspense fallback='Loading...'>
              <LazyAbout />
            </React.Suspense>
          }/>
        </Routes>
        <Footer></Footer> 
        </BrowserRouter>
    </div>
  );
}

export default App;
