import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import './MenuIcon.css'
import './Nav.css'
function MenuIcon({toggleOpen, open}) {
  const location = useLocation()
  const [intersection, setIntersection] = useState(true)

  window.addEventListener("scroll", () => {

    if(window.scrollY > window.innerHeight / 100 * 90) {
      setIntersection(false)
    } else {
      setIntersection(true)
    }

  });


  
    if(location.pathname === "/about") {
      return (
        <div onClick={() => toggleOpen()} className={`menu-icon ${open ? 'open' : ''}`}>

        
        { intersection ? (<>
            <div className="menu-icon-line line-1"></div>
            <div className="menu-icon-line line-2"></div>
            <div className="menu-icon-line line-3 menu-icon-line-last"></div>

          </>)
          
          :   
          
          (<>
          
          <div className="menu-icon-line-dark line-1"></div>
          <div className="menu-icon-line-dark line-2"></div>
          <div className="menu-icon-line-dark line-3 menu-icon-line-last"></div>
          
          </>)
        }
      </div>
      )
    }
  else 

  
  return (
      <div onClick={() => toggleOpen()} className={`menu-icon ${open ? 'open' : ''}`}>

        
        {location.pathname === "/" ? (<>
            <div className="menu-icon-line line-1"></div>
            <div className="menu-icon-line line-2"></div>
            <div className="menu-icon-line line-3 menu-icon-line-last"></div>

          </>)
          
          :   
          
          (<>
          
          <div className="menu-icon-line-dark line-1"></div>
          <div className="menu-icon-line-dark line-2"></div>
          <div className="menu-icon-line-dark line-3 menu-icon-line-last"></div>
          
          </>)
        }
      </div>
  )
}

export default MenuIcon