import './Home.css'
import '../../App.css'
import headerMain from '../../assets/header-main-new.png'
import headerMainMobile from '../../assets/header-main-mobile.png'
import headerVideo from '../../assets/qt-compress-5.mov'
import sponsors from '../../assets/logos.jpg'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Home() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])




  return (
    <div> 
      <header className="header">
        <div className="video-overlay"></div>
        <img src={headerMain} className='desktop' alt="" />
        <img src={headerMainMobile} alt="" className='mobile'/>
        <video playsInline autoPlay muted loop preload='auto' >
            <source src={headerVideo} type="video/webm" />
            <source src={headerVideo} type="video/mp4" />
          </video>
        </header>

    <section className="section section-second">
       <p className="p-bigger">“A Mediterranean city  <span className='zidane-wrap' > is really my culture.”</span>  <br/>
       
        <span className='zidane'>  — Zinedine Zidane</span>
    </p>
       
    </section>

    <section className="section section-fashion">
        <div className="section-cta">
            <h2 className="event-name"> <span className="event-name-blue">med</span> fashion</h2>
            <Link to={'/med-fashion'}><button className="btn-primary">see more</button></Link>  
        </div>
    </section>

    <section className="section section-cuisine">
        <div className="section-cta">
            <h2 className="event-name"> <span className="event-name-blue">med</span> cuisine</h2>
            <Link to={'/med-cuisine'}><button className="btn-primary">see more</button></Link>  
        </div>
    </section>

    <section className="section section-yacht">
        <div className="section-cta">
            <h2 className="event-name"> <span className="event-name-blue">med</span> yacht design</h2>
            <Link to={'/med-yacht-design'}><button className="btn-primary">see more</button></Link>
        </div>
    </section>

    <section className="section section-beauty">
        <div className="section-cta section-cta-beauty">
            <h2 className="event-name"> <span className="event-name-blue">med</span> beauty</h2>
            <Link to={'/med-beauty'}><button className="btn-primary">see more</button></Link>
        </div>
    </section>

    <section className="section-sponsors">
        <h3>PATRONS & SPONSORS</h3>
        <div className="image-wrapper">
            <img src={sponsors} alt="" />
        </div>
    </section>

    </div>
  )
}

export default Home