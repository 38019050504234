import './Nav.css'
import '../pages/home/Home.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import MenuIcon from './MenuIcon'

function Nav() {


  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }
 
  return ( 
    <div className='nav' onClick={() => toggleOpen()}>

          <MenuIcon toggleOpen={toggleOpen} open={open}></MenuIcon>

              <div className={`nav-links nav-links-mobile ${open ? 'active' : ''}`}>
              <div className="logo" style={{borderBottom: "none"}} > <span className="logo-bold"> Med </span>culture platform </div>
                <ul>
                    <li><Link onClick={() => toggleOpen()} to={'/'}>Home</Link> </li>
                    <li><Link onClick={() => toggleOpen()} to={'/med-fashion'}>Med Fashion</Link> </li>
                    <li><Link onClick={() => toggleOpen()} to={'/med-cuisine'}>Med Cuisine</Link> </li>
                    <li><Link onClick={() => toggleOpen()} to={'/med-yacht-design'}>Med Yacht Design</Link> </li>
                    <li><Link onClick={() => toggleOpen()} to={'/med-beauty'}>Med Beauty</Link> </li>
                    <li><Link onClick={() => toggleOpen()} to={'/about'}>About</Link> </li>
                 </ul>
              </div>
    </div>
  )
}

export default Nav