import { Link, useNavigate } from 'react-router-dom'
import './Footer.css'
import './Logo.css'
import '../pages/home/Home.css'

function Footer() {
  const navigate = useNavigate()
  return (
   <>
    <footer className="footer">
        <div className="logo" style={{marginBottom: '70px'}} onClick={() => navigate('/')}> <span className="logo-bold"> Med </span>culture platform </div>
        <div className="footer-nav">
            <div className="footer-nav-left">
                <h4>Navigation</h4>
                <ul>
                    <li><Link to={'/med-fashion'}>Med Fashion</Link></li>
                    <li><Link to={'/med-cuisine'}>Med Cuisine</Link></li>
                    <li><Link to={'/med-yacht-design'}>Med Yacht Design</Link></li>
                    <li><Link to={'/med-beauty'}>Med Beauty</Link></li>
                    <li><Link to={'/about'}>About</Link></li>
                </ul>
            </div>
            <div className="footer-nav-right">
                <ul>
                    <li>Phone: +382 67 388 172</li>
                    <li>Email: mediteranaognjiste@gmail.com</li>
                    <li>Adress: Boka Bay, Montenegro</li>
                </ul>
            </div>
        </div>

        <hr />

        <p><span>&#169;</span> 2023 Ognjiste Mediterana. All rights reserved. </p>

    </footer>

   </>
  )
}

export default Footer